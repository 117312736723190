














































import dateFormat from "@/filters/date.filter";
import { APagination, useSort, useUnitReceiptMonitoring } from "@/hooks";
import MNotification from "@/mixins/MNotification.vue";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import {
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  PAGE_SIZE_OPTIONS,
} from "@/models/constant/global.constant";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import {
  UnitReceiptMonitoringFormState,
  UnitReceiptMonitoringResponseDto,
} from "@/models/interface/unit-receipt-monitoring";
import { unitReceiptMonitoringService } from "@/services/unit-receipt-monitoring.service";
import { ColumnDef, SortDirection, SorterProps } from "@/types";
import Vue from "vue";

export default Vue.extend({
  name: "UnitReceiptMonitoringReceivedTable",
  mixins: [MNotification],
  props: {
    filter: {
      type: Object as () => UnitReceiptMonitoringFormState,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      PAGE_SIZE_OPTIONS,
      dataSource: [] as UnitReceiptMonitoringResponseDto[],
      pagination: {
        page: DEFAULT_PAGE,
        limit: DEFAULT_PAGE_SIZE,
        totalElements: 0,
        sorts: "bapuDate:desc",
      },
      loading: false,
      columns: [
        {
          title: this.$t("lbl_bapu_number"),
          dataIndex: "bapuNumber",
          key: "bapuNumber",
          sorter: true,
          customRender: (text: string) => text || "-",
        },
        {
          title: this.$t("lbl_bapu_date"),
          dataIndex: "bapuDate",
          key: "bapuDate",
          sorter: true,
          defaultSortOrder: "descend",
          customRender: (text: string) => dateFormat(text),
        },
        {
          title: this.$t("lbl_unit_code"),
          dataIndex: "unitCode",
          key: "unitCode",
          sorter: true,
        },
        {
          title: this.$t("lbl_ic_number"),
          dataIndex: "icNumber",
          key: "icNumber",
          width: "150px",
          sorter: true,
          customRender: (text: string) => text || "-",
        },
        {
          title: this.$t("lbl_customer"),
          dataIndex: "customerName",
          key: "customerName",
          sorter: true,
        },
        {
          title: this.$t("lbl_unit_receipt_date"),
          dataIndex: "unitReceiptDate",
          key: "unitReceiptDate",
          sorter: true,
          customRender: (text: string) => dateFormat(text),
        },
        {
          title: this.$t("lbl_location_receipt"),
          dataIndex: "locationReceipt",
          key: "locationReceipt",
          customRender: (text: string) => text || "-",
          sorter: true,
        },
        {
          title: this.$t("lbl_recipient"),
          dataIndex: "recipientName",
          key: "recipientName",
          customRender: (text: string) => text || "-",
          sorter: true,
        },
        {
          key: "attachment",
          dataIndex: "attachment",
          scopedSlots: { customRender: "attachment" },
          align: "center",
          width: "50px",
        },
      ] as ColumnDef[],
    };
  },
  watch: {
    filter: {
      handler(newValue: undefined | UnitReceiptMonitoringFormState) {
        const params = new RequestQueryParams();
        this.pagination.page = DEFAULT_PAGE;
        params.limit = this.pagination.limit;
        params.sorts = this.pagination.sorts;
        if (newValue) {
          params.search = this.buildSearch(newValue);
        }
        this.fetchList(params);
      },
    },
  },
  mounted() {
    const params = new RequestQueryParams();
    params.search = this.buildSearch(this.filter);
    params.sorts = this.pagination.sorts;
    this.fetchList(params);
  },
  methods: {
    async fetchList(params?: RequestQueryParamsModel): Promise<void> {
      try {
        this.loading = true;
        this.$emit("loading", true);
        const response = await unitReceiptMonitoringService.getList(params);
        this.dataSource = response.data;
        this.pagination.totalElements = response.totalElements;
      } catch {
        this.showNotifError("notif_process_fail");
        this.dataSource = [];
      } finally {
        this.loading = false;
        this.$emit("loading", false);
      }
    },
    buildSearch(state: UnitReceiptMonitoringFormState | undefined): string {
      const { filterBy } = useUnitReceiptMonitoring();
      return filterBy({
        customerId: state?.customer?.key,
        icId: state?.icNumber?.key,
        unitCode: state?.unitCode,
        bapuNumber: state?.bapu,
        isReceived: "true",
      });
    },
    buildSorts(
      columnKey: string,
      direction: SortDirection | undefined
    ): string {
      if (!direction) return "";

      const { toOrder } = useSort();
      const dir = toOrder(direction);
      const sorter = {
        bapuNumber: `${columnKey}:${dir}`,
        bapuDate: `${columnKey}:${dir}`,
        unitCode: `${columnKey}:${dir}`,
        customerName: `${columnKey}:${dir}`,
        unitReceiptDate: `${columnKey}:${dir}`,
        locationReceipt: `${columnKey}:${dir}`,
        recipientName: `${columnKey}:${dir}`,
        icNumber: `${columnKey}:${dir}`,
      };

      return sorter[columnKey];
    },
    onChangeTable(
      { current, pageSize }: APagination,
      _,
      { columnKey, order }: SorterProps
    ): void {
      this.pagination.page =
        pageSize === this.pagination.limit ? current : DEFAULT_PAGE;
      this.pagination.limit = pageSize;
      this.pagination.sorts = this.buildSorts(columnKey, order);

      const params = new RequestQueryParams();
      params.search = this.buildSearch(this.filter);
      params.page = this.pagination.page - 1;
      params.limit = this.pagination.limit;
      params.sorts = this.pagination.sorts;

      this.fetchList(params);
    },
    downloadAttachment(attachment: string, bapuNumber: string) {
      const link = document.createElement("a");
      link.href = this.$sanitize(attachment);
      link.setAttribute("download", `${bapuNumber}-attachment`);
      link.click();
      link.remove();
    },
  },
});
