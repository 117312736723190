var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        visible: _vm.value,
        title: _vm.$t("lbl_unit_receipt"),
        width: "512px",
        "destroy-on-close": "",
        "data-testid": "unit-receipt-monitoring-receipt-modal"
      },
      on: { close: _vm.handleClose }
    },
    [
      _c(
        "a-form-model",
        {
          ref: "form",
          attrs: {
            model: _vm.formState,
            rules: _vm.validationSchema,
            "label-align": "left"
          }
        },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: _vm.$t("lbl_bapu_number") } },
            [
              _c(
                "span",
                {
                  staticClass: "ant-form-text",
                  attrs: {
                    "data-testid":
                      "unit-receipt-monitoring-receipt-modal-bapu-number"
                  }
                },
                [_vm._v(" " + _vm._s(_vm.payload.bapuNumber) + " ")]
              )
            ]
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: _vm.$t("lbl_unit_receipt_date"),
                prop: "receiptDate"
              }
            },
            [
              _c("a-date-picker", {
                attrs: {
                  format: _vm.DEFAULT_DATE_FORMAT,
                  "disabled-date": _vm.allowDate,
                  "data-testid":
                    "unit-receipt-monitoring-receipt-modal-receipt-date"
                },
                model: {
                  value: _vm.formState.receiptDate,
                  callback: function($$v) {
                    _vm.$set(_vm.formState, "receiptDate", $$v)
                  },
                  expression: "formState.receiptDate"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: _vm.$t("lbl_location_receipt"),
                prop: "receiptLocation"
              }
            },
            [
              _c("SelectRack", {
                attrs: {
                  "value-as": "id",
                  "data-testid":
                    "unit-receipt-monitoring-receipt-modal-location"
                },
                model: {
                  value: _vm.formState.receiptLocation,
                  callback: function($$v) {
                    _vm.$set(_vm.formState, "receiptLocation", $$v)
                  },
                  expression: "formState.receiptLocation"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: _vm.$t("lbl_recipient"), prop: "recipient" } },
            [
              _c("SelectEmployee", {
                attrs: {
                  "label-in-value": "",
                  "data-testid":
                    "unit-receipt-monitoring-receipt-modal-recipient"
                },
                model: {
                  value: _vm.formState.recipient,
                  callback: function($$v) {
                    _vm.$set(_vm.formState, "recipient", $$v)
                  },
                  expression: "formState.recipient"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: _vm.$t("lbl_attachment"),
                prop: "attachment",
                "wrapper-col": { span: 12 },
                "label-col": { span: 7 }
              },
              scopedSlots: _vm._u([
                {
                  key: "extra",
                  fn: function() {
                    return [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("attachment.allow-common-type") +
                              ". " +
                              _vm.$t("attachment.allow-size")
                          )
                        )
                      ])
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c(
                "a-upload",
                {
                  attrs: {
                    "before-upload": _vm.handleBeforeUpload,
                    accept: "image/*,.pdf,.xlsx",
                    "custom-request": _vm.handleUploadAttachment,
                    "file-list": _vm.attachments,
                    remove: _vm.handleRemoveAttachment
                  }
                },
                [
                  _c(
                    "a-button",
                    {
                      attrs: {
                        icon: "upload",
                        "data-testid":
                          "unit-receipt-monitoring-receipt-modal-attachment",
                        loading: _vm.isLoadingAttachment
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_upload")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { type: "flex", gutter: [0, 8] } },
        [
          _c(
            "a-col",
            { attrs: { span: "24" } },
            [
              _c(
                "a-button",
                {
                  attrs: {
                    type: "primary",
                    loading: _vm.isLoadingSubmit,
                    block: "",
                    "data-testid":
                      "unit-receipt-monitoring-receipt-modal-receive"
                  },
                  on: { click: _vm.validateForm }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_received")) + " ")]
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: "24" } },
            [
              _c(
                "a-button",
                {
                  attrs: {
                    block: "",
                    "data-testid": "unit-receipt-monitoring-receipt-modal-close"
                  },
                  on: { click: _vm.handleClose }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_close")) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }