var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_unit_receipt_monitoring") } },
    [
      _c(
        "a-form-model",
        {
          ref: "form",
          attrs: { model: _vm.formState },
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.handleSubmit.apply(null, arguments)
            }
          }
        },
        [
          _c(
            "a-row",
            { attrs: { type: "flex", gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: "6" } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: { label: _vm.$t("lbl_bapu_number"), prop: "bapu" }
                    },
                    [
                      _c("a-input", {
                        attrs: {
                          "allow-clear": "",
                          placeholder: _vm.$t("common.insert-text", {
                            text: _vm.$t("lbl_bapu_number")
                          }),
                          "data-testid": "unit-receipt-monitoring-bapu"
                        },
                        model: {
                          value: _vm.formState.bapu,
                          callback: function($$v) {
                            _vm.$set(_vm.formState, "bapu", $$v)
                          },
                          expression: "formState.bapu"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: "6" } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_unit_code"),
                        prop: "unitCode"
                      }
                    },
                    [
                      _c("a-input", {
                        attrs: {
                          "allow-clear": "",
                          placeholder: _vm.$t("common.insert-text", {
                            text: _vm.$t("lbl_unit_code")
                          }),
                          "data-testid": "unit-receipt-monitoring-unit-code"
                        },
                        model: {
                          value: _vm.formState.unitCode,
                          callback: function($$v) {
                            _vm.$set(_vm.formState, "unitCode", $$v)
                          },
                          expression: "formState.unitCode"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: "6" } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_ic_number"),
                        prop: "icNumber"
                      }
                    },
                    [
                      _c("SelectIcNumber", {
                        attrs: {
                          "allow-clear": "",
                          "data-testid": "unit-receipt-monitoring-ic-number"
                        },
                        model: {
                          value: _vm.formState.icNumber,
                          callback: function($$v) {
                            _vm.$set(_vm.formState, "icNumber", $$v)
                          },
                          expression: "formState.icNumber"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: "6" } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: { label: _vm.$t("lbl_customer"), prop: "customer" }
                    },
                    [
                      _c("SelectCustomer", {
                        attrs: {
                          "label-in-value": "",
                          "data-testid": "unit-receipt-monitoring-customer"
                        },
                        model: {
                          value: _vm.formState.customer,
                          callback: function($$v) {
                            _vm.$set(_vm.formState, "customer", $$v)
                          },
                          expression: "formState.customer"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { type: "flex" } },
            [
              _c(
                "a-col",
                [
                  _c(
                    "a-space",
                    [
                      _c(
                        "a-button",
                        {
                          attrs: {
                            "html-type": "button",
                            "data-testid": "unit-receipt-monitoring-clear"
                          },
                          on: { click: _vm.handleClear }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_clear")) + " ")]
                      ),
                      _c(
                        "a-button",
                        {
                          attrs: {
                            type: "primary",
                            loading: _vm.loading.find,
                            "data-testid": "unit-receipt-monitoring-find",
                            "html-type": "submit"
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_find")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-tabs",
        {
          staticClass: "mt-5",
          attrs: { type: "card" },
          model: {
            value: _vm.currentTab,
            callback: function($$v) {
              _vm.currentTab = $$v
            },
            expression: "currentTab"
          }
        },
        _vm._l(_vm.tabs, function(tab) {
          return _c(
            "a-tab-pane",
            { key: tab.key, attrs: { tab: tab.title } },
            [
              tab.key === _vm.currentTab
                ? _c(tab.component, {
                    tag: "component",
                    attrs: {
                      filter: _vm.filter,
                      "data-testid": "unit-receipt-monitoring-" + tab.key
                    },
                    on: {
                      loading: function(val) {
                        _vm.loading.find = val
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        }),
        1
      ),
      _c(
        "div",
        { staticClass: "mt-3 text-right" },
        [
          _c(
            "a-dropdown-button",
            {
              attrs: {
                type: "primary",
                disabled: _vm.loading.download,
                "data-testid": "unit-receipt-monitoring-download"
              },
              on: { click: _vm.handleDownloadAll },
              scopedSlots: _vm._u([
                {
                  key: "icon",
                  fn: function() {
                    return [_c("a-icon", { attrs: { type: "setting" } })]
                  },
                  proxy: true
                },
                {
                  key: "overlay",
                  fn: function() {
                    return [
                      _c(
                        "a-menu",
                        { on: { click: _vm.handleDownloadByKey } },
                        [
                          _c(
                            "a-menu-item",
                            {
                              key: "need-received",
                              attrs: {
                                "data-testid":
                                  "unit-receipt-monitoring-download-need-received"
                              }
                            },
                            [
                              _c("a-icon", { attrs: { type: "download" } }),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("common.only-text", {
                                      text: _vm.$t("lbl_need_to_received")
                                    })
                                  ) +
                                  " "
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-menu-item",
                            {
                              key: "received",
                              attrs: {
                                "data-testid":
                                  "unit-receipt-monitoring-download-received"
                              }
                            },
                            [
                              _c("a-icon", { attrs: { type: "download" } }),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("common.only-text", {
                                      text: _vm.$t("lbl_only_received")
                                    })
                                  ) +
                                  " "
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _vm._v(" " + _vm._s(_vm.$t("lbl_download")) + " "),
              _vm.loading.download
                ? _c("a-icon", { attrs: { type: "loading" } })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }