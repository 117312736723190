










































































































import SelectEmployee from "@/components/custom/select/EmployeeSelect.vue";
import SelectRack from "@/components/custom/select/SelectRack.vue";
import { useMinByDate, useValidateMaximumFileSize } from "@/hooks";
import MNotification from "@/mixins/MNotification.vue";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { UnitReceiptMonitoringRequestDto } from "@/models/interface/unit-receipt-monitoring";
import { unitReceiptMonitoringService } from "@/services/unit-receipt-monitoring.service";
import { LabelInValue } from "@/types";
import { FormModel } from "ant-design-vue";
import { UploadFile } from "ant-design-vue/types/upload";
import moment, { Moment } from "moment";
import Vue from "vue";

type FormState = {
  receiptDate: Moment | null;
  receiptLocation: undefined | LabelInValue;
  recipient: undefined | LabelInValue;
  attachments: string | null;
};

function initFormState(): FormState {
  return {
    receiptDate: null,
    receiptLocation: undefined,
    recipient: undefined,
    attachments: null,
  };
}

export default Vue.extend({
  name: "UnitReceiptMonitoringReceiptModal",
  components: {
    SelectRack,
    SelectEmployee,
  },
  mixins: [MNotification],
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false,
    },
    payload: {
      type: Object as () => {
        endContractDate: string;
        id: string;
        bapuNumber: string;
      },
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      DEFAULT_DATE_FORMAT,
      isLoadingAttachment: false,
      isLoadingSubmit: false,
      attachments: [] as UploadFile[],
      formState: initFormState(),
      validationSchema: {
        receiptDate: [
          { required: true, message: this.$t("lbl_validation_required_error") },
        ],
        receiptLocation: [
          { required: true, message: this.$t("lbl_validation_required_error") },
        ],
        recipient: [
          { required: true, message: this.$t("lbl_validation_required_error") },
        ],
      },
    };
  },
  methods: {
    handleClose(): void {
      this.attachments = [];
      this.formState = initFormState();
      this.$emit("input", false);
    },
    emitFinish(): void {
      this.attachments = [];
      this.formState = initFormState();
      this.$emit("input", false);
      this.$emit("finish");
    },
    buildRequest(state: FormState): UnitReceiptMonitoringRequestDto {
      return {
        receiveDate: state.receiptDate?.format() ?? "",
        locationId: state.receiptLocation?.key ?? "",
        recipientId: state.recipient?.key ?? "",
        attachment: state.attachments,
      };
    },
    validateForm() {
      const form = this.$refs.form as FormModel;
      form.validate(valid => {
        if (!valid) return;

        this.handleSubmit(this.payload.id, this.formState);
      });
    },
    async handleSubmit(id: string, state: FormState): Promise<void> {
      try {
        this.isLoadingSubmit = true;
        const request: UnitReceiptMonitoringRequestDto =
          this.buildRequest(state);
        await unitReceiptMonitoringService.receiptUnit(id, request);
        this.showNotifSuccess("notif_document_has_been_receipt", {
          document: this.payload.bapuNumber,
        });
        this.emitFinish();
      } catch {
        this.showNotifError("notif_process_fail");
      } finally {
        this.isLoadingSubmit = false;
      }
    },
    allowDate(date: Moment) {
      if (!this.payload || !this.payload.endContractDate) return false;

      return (
        useMinByDate(date, moment(this.payload.endContractDate)) &&
        moment().endOf("day")
      );
    },
    async handleUploadAttachment({ file }: { file: File }): Promise<void> {
      try {
        this.isLoadingAttachment = true;
        const attachments = [...this.attachments];
        this.attachments = attachments.slice(-1);

        const fd = new FormData();
        fd.append("file", file);

        const response = await unitReceiptMonitoringService.uploadAttachment(
          fd
        );
        this.formState.attachments = response.url;
        this.attachments[0].url = response.url;

        this.showNotifSuccess("notif_file_upload_successfully", {
          filename: file.name,
        });
      } catch (error) {
        this.formState.attachments = null;
        this.showNotifError("notif_file_upload_failed", {
          filename: file.name,
        });
      } finally {
        this.isLoadingAttachment = false;
      }
    },
    handleBeforeUpload(file: UploadFile) {
      const isValid = this.validationFileSize(file);
      if (!isValid) return false;

      this.attachments.push(file);

      return true;
    },
    validationFileSize(file: UploadFile): boolean {
      if (useValidateMaximumFileSize(file.size)) {
        this.showNotifWarning(this.$t("attachment.allow-size"));
        return false;
      }

      return true;
    },
    handleRemoveAttachment(): boolean {
      this.attachments.splice(0, 1);
      this.formState.attachments = null;
      return true;
    },
  },
});
