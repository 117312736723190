























































































































import { useBlob, useUnitReceiptMonitoring } from "@/hooks";
import MNotification from "@/mixins/MNotification.vue";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { unitReceiptMonitoringService } from "@/services/unit-receipt-monitoring.service";
import { UnitReceiptMonitoringFormState } from "@interface/unit-receipt-monitoring";
import { FormModel } from "ant-design-vue";
import Vue from "vue";
import SelectCustomer from "../custom/select/SelectCustomer.vue";
import SelectIcNumber from "../custom/select/SelectIcNumber.vue";
import UnitReceiptMonitoringNeedReceiptTable from "./UnitReceiptMonitoringNeedReceiptTable.vue";
import UnitReceiptMonitoringReceivedTable from "./UnitReceiptMonitoringReceivedTable.vue";

type TabKeyEnum = "need-received" | "received";

export default Vue.extend({
  name: "UnitReceiptMonitoringWidget",
  mixins: [MNotification],
  components: {
    SelectIcNumber,
    SelectCustomer,
    UnitReceiptMonitoringReceivedTable,
    UnitReceiptMonitoringNeedReceiptTable,
  },
  data() {
    return {
      formState: {
        unitCode: undefined,
        customer: undefined,
        icNumber: undefined,
        bapu: undefined,
      } as UnitReceiptMonitoringFormState,
      form: undefined as FormModel | undefined,
      currentTab: "need-received" as TabKeyEnum,
      filter: undefined as UnitReceiptMonitoringFormState | undefined,
      loading: {
        find: false,
        download: false,
      },
      tabs: [
        {
          title: this.$t("lbl_need_to_received"),
          key: "need-received",
          component: "UnitReceiptMonitoringNeedReceiptTable",
        },
        {
          title: this.$t("lbl_received"),
          key: "received",
          component: "UnitReceiptMonitoringReceivedTable",
        },
      ],
    };
  },
  mounted() {
    if (this.$refs.form) {
      this.form = this.$refs.form as FormModel;
    }
  },
  methods: {
    handleClear(): void {
      this.form?.resetFields();
    },
    handleSubmit(): void {
      this.filter = { ...this.formState };
    },
    async download(
      filename: string,
      params?: RequestQueryParamsModel
    ): Promise<void> {
      const { toDownload } = useBlob();
      try {
        this.loading.download = true;
        const response = await unitReceiptMonitoringService.download(params);
        toDownload(response, filename);
      } catch {
        this.showNotifError("notif_download_error");
      } finally {
        this.loading.download = false;
      }
    },
    buildParamsDownload(key?: TabKeyEnum) {
      const { filterBy } = useUnitReceiptMonitoring();
      const params = new RequestQueryParams();
      params.search = filterBy({
        bapuNumber: this.formState.bapu,
        customerId: this.formState.customer?.key,
        icId: this.formState.icNumber?.key,
        unitCode: this.formState.unitCode,
        isReceived: key
          ? key === "need-received"
            ? "false"
            : "true"
          : undefined,
      });
      params.params = this.buildHeaderReport(this.formState, key);
      return params;
    },
    buildHeaderReport(
      state: UnitReceiptMonitoringFormState,
      key?: TabKeyEnum
    ): string {
      const company = this.$store.state.authStore.authData.companyName;
      const unitCode = state.unitCode || "ALL";
      const icNumber = state.icNumber?.label.trim() || "ALL";
      const customerName = state.customer?.label.trim() || "ALL";
      const isReceived = key
        ? key === "need-received"
          ? this.$t("lbl_need_to_received")
          : this.$t("lbl_received")
        : "ALL";

      const header: string[] = [
        company,
        unitCode,
        icNumber,
        customerName,
        isReceived,
      ];

      return header.join(",");
    },
    handleDownloadByKey({ key }: { key: TabKeyEnum }) {
      const params: RequestQueryParamsModel = this.buildParamsDownload(key);
      this.download("unit-receipt-monitoring.xlsx", params);
    },
    handleDownloadAll() {
      const params: RequestQueryParamsModel = this.buildParamsDownload();
      this.download("unit-receipt-monitoring.xlsx", params);
    },
  },
});
