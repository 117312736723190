import { Api } from "@/models/class/api.class";
import { Pagination } from "@/models/constant/interface/common.interface";
import {
  ErrorResponseModel,
  RequestQueryParamsModel,
} from "@/models/interface/http.interface";
import { FileCreateResponseDto } from "@/models/interface/storage";
import {
  UnitReceiptMonitoringRequestDto,
  UnitReceiptMonitoringResponseDto,
} from "@/models/interface/unit-receipt-monitoring";
import { AxiosError } from "axios";
import { HttpClient } from "./HttpClient.service";

class UnitReceiptMonitoringService extends HttpClient {
  getList(
    params?: RequestQueryParamsModel
  ): Promise<Pagination<UnitReceiptMonitoringResponseDto>> {
    return this.get<Pagination<UnitReceiptMonitoringResponseDto>>(
      Api.UnitReceiptMonitoringList,
      { params }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  uploadAttachment(payload: FormData): Promise<FileCreateResponseDto> {
    return this.post<FileCreateResponseDto, FormData>(
      Api.UnitReceiptMonitoringAttachmentBucket,
      payload,
      { headers: { "Content-Type": "multipart/form-data" } }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  receiptUnit(
    id: string,
    payload: UnitReceiptMonitoringRequestDto
  ): Promise<boolean> {
    return this.put<boolean, UnitReceiptMonitoringRequestDto>(
      `${Api.UnitReceiptMonitoringReceipt}/${id}`,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  download(params?: RequestQueryParamsModel): Promise<Blob> {
    return this.get<Blob>(Api.UnitReceiptMonitoringDownload, {
      params,
      responseType: "blob",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
}

export const unitReceiptMonitoringService = new UnitReceiptMonitoringService();
