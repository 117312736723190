var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("a-table", {
        attrs: {
          "data-source": _vm.dataSource,
          columns: _vm.columns,
          size: "small",
          loading: _vm.loading,
          "row-key": "id",
          "data-testid": "unit-receipt-monitoring-need-receipt-table",
          "row-class-name": function(_record, index) {
            return index % 2 ? "bg-white" : "bg-gray-light"
          },
          pagination: {
            showTotal: function() {
              return _vm.$t("lbl_total_items", {
                total: _vm.pagination.totalElements
              })
            },
            total: _vm.pagination.totalElements,
            showSizeChanger: true,
            pageSizeOptions: _vm.PAGE_SIZE_OPTIONS,
            current: _vm.pagination.page,
            defaultPageSize: _vm.pagination.limit
          },
          scroll: { y: 500 }
        },
        on: { change: _vm.onChangeTable },
        scopedSlots: _vm._u([
          {
            key: "unitReceive",
            fn: function(_, record) {
              return [
                _c(
                  "a-button",
                  {
                    attrs: {
                      type: "primary",
                      size: "small",
                      "data-testid":
                        "unit-receipt-monitoring-need-receipt-receive"
                    },
                    on: {
                      click: function($event) {
                        return _vm.handleReceiveUnit(record)
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("lbl_receive_unit")) + " ")]
                )
              ]
            }
          }
        ])
      }),
      _c("UnitReceiptMonitoringReceiptModal", {
        attrs: {
          "data-testid": "unit-receipt-monitoring-need-receipt-modal",
          payload: _vm.modalReceive.data
        },
        on: { finish: _vm.handleFinishReceipt },
        model: {
          value: _vm.modalReceive.visible,
          callback: function($$v) {
            _vm.$set(_vm.modalReceive, "visible", $$v)
          },
          expression: "modalReceive.visible"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }