




















import { debounceProcess } from "@/helpers/debounce";
import { useInternalContract } from "@/hooks";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { LabelInValue } from "@/types";
import Vue from "vue";
import Select from "../select/Select.vue";

export default Vue.extend({
  name: "SelectIcNumber",
  components: {
    Select,
  },
  props: {
    value: {
      required: true,
      default: undefined,
    },
    allowClear: {
      required: false,
      default: false,
    },
  },
  data() {
    this.onSearch = debounceProcess(this.onSearch);
    return {
      options: [] as Option[],
      loading: false,
    };
  },
  mounted() {
    this.fetchIcList();
  },
  methods: {
    onChange(e: LabelInValue | undefined): void {
      if (!e) {
        this.fetchIcList();
      }
      this.$emit("input", e);
    },
    onSearch(value?: string) {
      const { findBy } = useInternalContract();
      const params = new RequestQueryParams();
      params.search = findBy({ documentNo: value });
      this.fetchIcList(params);
    },
    async fetchIcList(params?: RequestQueryParamsModel): Promise<void> {
      const { findAll, toOptions } = useInternalContract();
      try {
        this.loading = true;
        const response = await findAll(params);
        this.options = toOptions(response.data);
      } catch {
        this.options = [];
      } finally {
        this.loading = false;
      }
    },
  },
});
