var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("a-table", {
        attrs: {
          "data-source": _vm.dataSource,
          columns: _vm.columns,
          size: "small",
          loading: _vm.loading,
          "row-key": "id",
          "data-testid": "unit-receipt-monitoring-received-table",
          "row-class-name": function(_record, index) {
            return index % 2 ? "bg-white" : "bg-gray-light"
          },
          pagination: {
            showTotal: function() {
              return _vm.$t("lbl_total_items", {
                total: _vm.pagination.totalElements
              })
            },
            total: _vm.pagination.totalElements,
            showSizeChanger: true,
            pageSizeOptions: _vm.PAGE_SIZE_OPTIONS,
            current: _vm.pagination.page,
            defaultPageSize: _vm.pagination.limit
          }
        },
        on: { change: _vm.onChangeTable },
        scopedSlots: _vm._u([
          {
            key: "attachment",
            fn: function(_, record) {
              return [
                record.attachment
                  ? [
                      _c(
                        "a-tooltip",
                        {
                          attrs: {
                            title: _vm.$t("lbl_download"),
                            placement: "left",
                            "destroy-tooltip-on-hide": ""
                          }
                        },
                        [
                          _c("a-button", {
                            attrs: {
                              type: "link",
                              icon: "download",
                              size: "small",
                              shape: "circle",
                              "data-testid":
                                "unit-receipt-monitoring-received-attachment"
                            },
                            on: {
                              click: function($event) {
                                return _vm.downloadAttachment(
                                  record.attachment,
                                  record.bapuNumber
                                )
                              }
                            }
                          })
                        ],
                        1
                      )
                    ]
                  : _vm._e()
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }