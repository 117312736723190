













































import dateFormat from "@/filters/date.filter";
import { APagination, useSort, useUnitReceiptMonitoring } from "@/hooks";
import MNotification from "@/mixins/MNotification.vue";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import {
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  PAGE_SIZE_OPTIONS,
} from "@/models/constant/global.constant";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import {
  UnitReceiptMonitoringFormState,
  UnitReceiptMonitoringResponseDto,
} from "@/models/interface/unit-receipt-monitoring";
import { unitReceiptMonitoringService } from "@/services/unit-receipt-monitoring.service";
import { ColumnDef, SortDirection, SorterProps } from "@/types";
import Vue from "vue";
import { mapGetters } from "vuex";
import UnitReceiptMonitoringReceiptModal from "./UnitReceiptMonitoringReceiptModal.vue";

export default Vue.extend({
  name: "UnitReceiptMonitoringNeedReceivedTable",
  components: {
    UnitReceiptMonitoringReceiptModal,
  },
  mixins: [MNotification],
  props: {
    filter: {
      type: Object as () => UnitReceiptMonitoringFormState,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      PAGE_SIZE_OPTIONS,
      modalReceive: {
        visible: false,
        data: {
          id: "",
          endContractDate: "",
          bapuNumber: "",
        },
      },
      dataSource: [] as UnitReceiptMonitoringResponseDto[],
      pagination: {
        page: DEFAULT_PAGE,
        limit: DEFAULT_PAGE_SIZE,
        totalElements: 0,
        sorts: "bapuDate:desc",
      },
      loading: false,
      columns: [
        {
          title: this.$t("lbl_bapu_number"),
          dataIndex: "bapuNumber",
          key: "bapuNumber",
          sorter: true,
          width: "200px",
          customRender: (text: string) => text || "-",
        },
        {
          title: this.$t("lbl_bapu_date"),
          dataIndex: "bapuDate",
          key: "bapuDate",
          sorter: true,
          defaultSortOrder: "descend",
          width: "150px",
          customRender: (text: string) => dateFormat(text),
        },
        {
          title: this.$t("lbl_unit_code"),
          dataIndex: "unitCode",
          key: "unitCode",
          width: "170px",
          sorter: true,
        },
        {
          title: this.$t("lbl_ic_number"),
          dataIndex: "icNumber",
          key: "icNumber",
          width: "150px",
          sorter: true,
          customRender: (text: string) => text || "-",
        },
        {
          title: this.$t("lbl_customer"),
          dataIndex: "customerName",
          key: "customerName",
          sorter: true,
        },
      ] as ColumnDef[],
    };
  },
  computed: {
    ...mapGetters({
      hasPrivilege: "authStore/hasPrivilege",
    }),
  },
  mounted() {
    this.allowReceiveUnit();

    const params = new RequestQueryParams();
    params.search = this.buildSearch(this.filter);
    params.sorts = this.pagination.sorts;
    this.fetchList(params);
  },
  watch: {
    filter: {
      handler(newValue: undefined | UnitReceiptMonitoringFormState) {
        const params = new RequestQueryParams();
        this.pagination.page = DEFAULT_PAGE;
        params.limit = this.pagination.limit;
        params.sorts = this.pagination.sorts;
        if (newValue) {
          params.search = this.buildSearch(newValue);
        }
        this.fetchList(params);
      },
    },
  },
  methods: {
    allowReceiveUnit(): void {
      if (this.hasPrivilege("unit-receipt-monitoring:update")) {
        this.columns.push({
          key: "unitReceive",
          scopedSlots: { customRender: "unitReceive" },
          align: "right",
        } as ColumnDef);
      }
    },
    async fetchList(params?: RequestQueryParamsModel): Promise<void> {
      try {
        this.loading = true;
        this.$emit("loading", true);
        const response = await unitReceiptMonitoringService.getList(params);
        this.dataSource = response.data;
        this.pagination.totalElements = response.totalElements;
      } catch {
        this.showNotifError("notif_process_fail");
        this.dataSource = [];
      } finally {
        this.loading = false;
        this.$emit("loading", false);
      }
    },
    buildSearch(state: UnitReceiptMonitoringFormState | undefined): string {
      const { filterBy } = useUnitReceiptMonitoring();
      return filterBy({
        customerId: state?.customer?.key ?? "",
        icId: state?.icNumber?.key ?? "",
        unitCode: state?.unitCode ?? "",
        bapuNumber: state?.bapu ?? "",
        isReceived: "false",
      });
    },
    buildSorts(
      columnKey: string,
      direction: SortDirection | undefined
    ): string {
      if (!direction) return "";

      const { toOrder } = useSort();
      const dir = toOrder(direction);
      const sorter = {
        bapuNumber: `${columnKey}:${dir}`,
        bapuDate: `${columnKey}:${dir}`,
        unitCode: `${columnKey}:${dir}`,
        customerName: `${columnKey}:${dir}`,
        icNumber: `${columnKey}:${dir}`,
      };

      return sorter[columnKey];
    },
    onChangeTable(
      { current, pageSize }: APagination,
      _,
      { columnKey, order }: SorterProps
    ): void {
      this.pagination.page =
        pageSize === this.pagination.limit ? current : DEFAULT_PAGE;
      this.pagination.limit = pageSize;
      this.pagination.sorts = this.buildSorts(columnKey, order);

      const params = new RequestQueryParams();
      params.search = this.buildSearch(this.filter);
      params.page = this.pagination.page - 1;
      params.limit = this.pagination.limit;
      params.sorts = this.pagination.sorts || undefined;

      this.fetchList(params);
    },
    handleReceiveUnit(record: UnitReceiptMonitoringResponseDto): void {
      this.modalReceive.data = {
        endContractDate: record.bapuDate,
        id: record.id,
        bapuNumber: record.bapuNumber,
      };
      this.modalReceive.visible = true;
    },
    handleFinishReceipt(): void {
      const params = new RequestQueryParams();
      params.search = this.buildSearch(this.filter);
      params.page = this.pagination.page - 1;
      params.limit = this.pagination.limit;
      params.sorts = this.pagination.sorts || undefined;

      this.fetchList(params);
    },
  },
});
